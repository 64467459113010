import dcmjs from 'dcmjs';
import { api } from 'dicomweb-client';

import DICOMWeb from '../DICOMWeb';
import parseDicomStructuredReport from './parseDicomStructuredReport';
import parseMeasurementsData from './parseMeasurementsData';
import getAllDisplaySets from './utils/getAllDisplaySets';
import errorHandler from '../errorHandler';
import getXHRRetryRequestHook from '../utils/xhrRetryRequestHook';

import { servicesManager } from '../../../viewer/src/App.js';

const VERSION_NAME = 'dcmjs-0.0';
const TRANSFER_SYNTAX_UID = '1.2.840.10008.1.2.1';

/**
 * Function to retrieve measurements from DICOM Structured Reports coming from determined server
 *
 * @param {Array} series - List of all series metaData loaded
 * @param {Array} studies - List of all studies metaData loaded
 * @param {string} serverUrl - Server URL to be used on request
 * @param {object} external
 * @returns {Object} MeasurementData
 */
const retrieveMeasurementFromSR = async (
  series,
  studies,
  serverUrl,
  external
) => {
  // const config = {
  //   url: serverUrl,
  //   headers: DICOMWeb.getAuthorizationHeader(),
  //   errorInterceptor: errorHandler.getHTTPErrorHandler(),
  //   requestHooks: [getXHRRetryRequestHook()],
  // };

  const token = localStorage.getItem('token');
  // if (!token) {
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('userData');
  //   window.location.href = '/';
  // }

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const config = {
    url: 'https://demo.dcmcloud.com',
    headers: headers, //DICOMWeb.getAuthorizationHeader(),
    errorInterceptor: errorHandler.getHTTPErrorHandler(),
    requestHooks: [getXHRRetryRequestHook()],
  };

  const dicomWeb = new api.DICOMwebClient(config);
  const instance = series.getFirstInstance();

  const BASE64 = window.location.href.split('%5E')[1];
  const forRegion = localStorage.getItem('userData');

  const jsonObject = JSON.parse(forRegion);

  const queryParameters = {
    base64: BASE64,
    region:
      jsonObject != null && jsonObject.storageLocation != null
        ? jsonObject.storageLocation
        : 'us-east-1',
  };

  const options = {
    studyInstanceUID: instance.getStudyInstanceUID(),
    seriesInstanceUID: instance.getSeriesInstanceUID(),
    sopInstanceUID: instance.getSOPInstanceUID(),
    queryParameters: queryParameters,
  };

  if (localStorage.getItem('once') == 0) {
    external.servicesManager = servicesManager;
    localStorage.setItem('once', 1);
  }

  const part10SRArrayBuffer = await dicomWeb.retrieveInstance(options);
  const displaySets = getAllDisplaySets(studies);
  const measurementsData = parseDicomStructuredReport(
    part10SRArrayBuffer,
    displaySets,
    external
  );

  const dataset = dcmjs.data.DicomMessage.readFile(part10SRArrayBuffer);

  const dicomDictionary = dcmjs.data.DicomMetaDictionary.namifyDataset(
    dataset.dict
  );

  updateMeasurementsData(dicomDictionary, measurementsData);

  for (const key in measurementsData) {
    if (measurementsData.hasOwnProperty(key)) {
      const dataArray = measurementsData[key];
      if (Array.isArray(dataArray) && dataArray.length > 0) {
        const locationDescriptionPairs = dataArray.map(item => ({
          location: item.location,
          description: item.description,
        }));

        locationDescriptionPairs.reverse();

        dataArray.forEach((item, index) => {
          item.location = locationDescriptionPairs[index].location;
          item.description = locationDescriptionPairs[index].description;
        });
      }
    }
  }

  return measurementsData;
};

/**
 * Function to store measurements to DICOM Structured Reports in determined server
 *
 * @param {Object} measurements - DCMCloud measurementData object
 * @param {string} serverUrl - Server URL to be used on request
 * @returns {Promise}
 */
const stowSRFromMeasurements = async (measurements, serverUrl) => {
  const { dataset } = parseMeasurementsData(measurements);
  const newtexts = measurements['allTools'].map(measurement => {
    const location = measurement.location || '...';
    const description = measurement.description || '...';
    return `${location} (${description})`;
  });

  appendTextToLengthTool(dataset, newtexts);

  const { DicomMetaDictionary, DicomDict } = dcmjs.data;
  const meta = {
    FileMetaInformationVersion: dataset._meta.FileMetaInformationVersion.Value,
    MediaStorageSOPClassUID: dataset.SOPClassUID,
    MediaStorageSOPInstanceUID: dataset.SOPInstanceUID,
    TransferSyntaxUID: TRANSFER_SYNTAX_UID,
    ImplementationClassUID: DicomMetaDictionary.uid(),
    ImplementationVersionName: VERSION_NAME,
  };

  const denaturalized = DicomMetaDictionary.denaturalizeDataset(meta);
  const dicomDict = new DicomDict(denaturalized);

  dicomDict.dict = DicomMetaDictionary.denaturalizeDataset(dataset);

  const part10Buffer = dicomDict.write();

  // const config = {
  //   url: serverUrl,
  //   headers: DICOMWeb.getAuthorizationHeader(),
  //   errorInterceptor: errorHandler.getHTTPErrorHandler(),
  //   requestHooks: [getXHRRetryRequestHook()],
  // };

  const token = localStorage.getItem('token');

  // if (!token) {
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('userData');
  //   window.location.href = '/';
  // }

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const config = {
    url: 'https://demo.dcmcloud.com',
    headers: headers, //DICOMWeb.getAuthorizationHeader(),
    errorInterceptor: errorHandler.getHTTPErrorHandler(),
    requestHooks: [getXHRRetryRequestHook()],
  };

  const BASE64 = window.location.href.split('%5E')[1];
  const forRegion = localStorage.getItem('userData');

  const jsonObject = JSON.parse(forRegion);

  const queryParameters = {
    base64: BASE64,
    region:
      jsonObject != null && jsonObject.storageLocation != null
        ? jsonObject.storageLocation
        : 'us-east-1',
  };

  const dicomWeb = new api.DICOMwebClient(config);
  const options = {
    datasets: [part10Buffer],
    queryParameters: queryParameters,
  };

  await dicomWeb.storeInstances(options);
};

const appendTextToLengthTool = (dataset, text) => {
  dataset.ContentSequence.forEach(contentItem => {
    if (
      contentItem.ConceptNameCodeSequence.CodeMeaning === 'Imaging Measurements'
    ) {
      let i = 0;
      contentItem.ContentSequence.forEach(measurementGroup => {
        if (!Array.isArray(measurementGroup.ContentSequence)) {
          measurementGroup.ContentSequence = [];
        }

        measurementGroup.ContentSequence.push({
          RelationshipType: 'CONTAINS',
          ValueType: 'TEXT',
          ConceptNameCodeSequence: [
            {
              CodeValue: 'CORNERSTONEFREETEXT',
              CodingSchemeDesignator: 'CST4',
              CodeMeaning: 'Label/Description',
            },
          ],
          TextValue: text[i],
        });

        i++;
      });
    }
  });
};

const updateMeasurementsData = (dataset, measurementsData) => {
  let newnumber = 0;
  for (const key in measurementsData) {
    if (measurementsData.hasOwnProperty(key)) {
      const dataArray = measurementsData[key];
      if (Array.isArray(dataArray) && dataArray.length > 0) {
        dataArray.forEach(item => {
          if (item.measurementNumber > newnumber) {
            newnumber = item.measurementNumber;
          }
        });
      }
    }
  }
  dataset.ContentSequence.Value.forEach(contentItem => {
    if (
      contentItem.ConceptNameCodeSequence.Value[0].CodeMeaning.Value[0] ===
      'Imaging Measurements'
    ) {
      contentItem.ContentSequence.Value.forEach(measurementGroup => {
        if (!Array.isArray(measurementGroup.ContentSequence.Value)) {
          measurementGroup.ContentSequence.Value = [];
        }

        measurementGroup.ContentSequence.Value.forEach(item => {
          if (
            item.ConceptNameCodeSequence.Value[0].CodeMeaning.Value[0] ===
            'Label/Description'
          ) {
            for (const key in measurementsData) {
              if (measurementsData.hasOwnProperty(key)) {
                const dataArray = measurementsData[key];
                if (Array.isArray(dataArray) && dataArray.length > 0) {
                  dataArray.forEach(newItem => {
                    if (newItem.measurementNumber == newnumber) {
                      let value = item.TextValue.Value[0];
                      let parts = value.split('(');
                      newItem.location = parts[0].trim();
                      newItem.description = parts[1]
                        ? parts[1].replace(')', '').trim()
                        : '';
                      newnumber--;
                    }
                  });
                }
              }
            }
          }
        });
      });
    }
  });
};

export { retrieveMeasurementFromSR, stowSRFromMeasurements };
