import './MeasurementTable.styl';
import cornerstoneTools from 'cornerstone-tools';
import React, { Component } from 'react';
import { withTranslation } from '../../contextProviders';

import { Icon } from './../../elements/Icon';
import { MeasurementTableItem } from './MeasurementTableItem.js';
import { OverlayTrigger } from './../overlayTrigger';
import PropTypes from 'prop-types';
import { ScrollableArea } from './../../ScrollableArea/ScrollableArea.js';
import { TableList } from './../tableList';
import { Tooltip } from './../tooltip';
import { withAppContext } from '../../../../viewer/src/context/AppContext.js';
import './tab.css';
import Findings from './Findings.js';

class MeasurementTable extends Component {
  static propTypes = {
    measurementCollection: PropTypes.array.isRequired,
    timepoints: PropTypes.array.isRequired,
    overallWarnings: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    onItemClick: PropTypes.func,
    onRelabelClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onHideClick: PropTypes.func,
    onUnhideClick: PropTypes.func,
    onEditDescriptionClick: PropTypes.func,
    selectedMeasurementNumber: PropTypes.number,
    t: PropTypes.func,
    saveFunction: PropTypes.func,
    onSaveComplete: PropTypes.func,
  };

  static defaultProps = {
    overallWarnings: {
      warningList: [],
    },
    readOnly: false,
  };

  state = {
    selectedKey: null,
    tab: false,
    description: '',
    isLoading: false,
    saveLoader: false,
    updatedMeasurement: [],
  };

  async componentDidMount() {
    this.updateLabels();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.measurementCollection !== prevProps.measurementCollection) {
      this.updateLabels();
    }
  }

  updateLabels = () => {
    const measurementCollection =
      this.props.measurementCollection &&
      this.props.measurementCollection[0] &&
      this.props.measurementCollection[0].measurements
        ? this.props.measurementCollection
            .map(collectionItem => {
              const updatedMeasurements = collectionItem.measurements.map(
                (m, index, array) => {
                  if (m.label) {
                    const baseLabel = m.label.split('(')[0].trim();
                    let count = 1;

                    for (let i = 0; i < index; i++) {
                      if (
                        array[i].label.split('(')[0].trim() === baseLabel &&
                        baseLabel != '...'
                      ) {
                        count++;
                      }
                    }

                    let newLabel =
                      count > 1 ? `${baseLabel} - ${count}` : baseLabel;

                    const originalBracketContent = m.label.includes('(')
                      ? m.label.substring(m.label.indexOf('('))
                      : '';
                    newLabel += ' ' + originalBracketContent;

                    return {
                      ...m,
                      label: newLabel,
                    };
                  }
                  return m;
                }
              );

              return {
                ...collectionItem,
                measurements: updatedMeasurements,
              };
            })
            .filter(Boolean)
        : [];

    this.setState({ updatedMeasurement: measurementCollection });
  };

  setDescription = description => {
    this.setState({ description: description });
  };

  setLoading = loading => {
    this.setState({ isLoading: loading });
  };

  // getFindings = async () => {
  //   const labels =
  //     this.props.measurementCollection &&
  //     this.props.measurementCollection[0] &&
  //     this.props.measurementCollection[0].measurements
  //       ? this.props.measurementCollection[0].measurements
  //           .map(m => {
  //             if (m.label) {
  //               let label = m.label.split('(')[0].trim();
  //               return {
  //                 label: label,
  //                 measurementId: m.measurementId,
  //               };
  //             }
  //           })
  //           .filter(Boolean)
  //       : [];

  //   const forRegion = localStorage.getItem('userData');
  //   const jsonObject = JSON.parse(forRegion);
  //   const StudyInstanceUID = window.location.href
  //     .split('/viewer/')[1]
  //     .split('%5E')[0];
  //   const base64 = window.location.href.split('%5E')[1];
  //   const token = localStorage.getItem('token');
  //   const url = `https://demo.dcmcloud.com/get_findings2?base64=${base64}&StudyInstanceUID=${StudyInstanceUID}&region=${jsonObject.storageLocation}`;

  //   this.setState({ isLoading: true });

  //   fetch(url, {
  //     method: 'GET',
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data.status === 'success') {
  //         let description = data.description;

  //         // labels.forEach(({ label, measurementId }) => {
  //         //   const regex = new RegExp(
  //         //     `<u id="dynamicspan-[^"]*">(${label})</u>`,
  //         //     'g'
  //         //   );
  //         //   description = description.replace(
  //         //     regex,
  //         //     `<u id="dynamicspan-${measurementId}">$1</u>`
  //         //   );
  //         // });

  //         this.setState({ isLoading: false, description: description });
  //       } else {
  //         this.setState({ isLoading: false });
  //         console.error('Error retrieving HTML description:', data.message);
  //       }
  //     })
  //     .catch(error => {
  //       this.setState({ isLoading: false });
  //       console.error('Fetch error:', error);
  //     });
  // };

  spinner = () => {
    return <div className="spinner mr-1 mt-1"></div>;
  };

  closeTable = () => {
    const tooltipElement = document.querySelector('.right-side-panel');
    if (tooltipElement) {
      tooltipElement.style.display = 'none'; // Hide the tooltip
    }
  };

  render() {
    const { overallWarnings, saveFunction, t } = this.props;
    const hasOverallWarnings = overallWarnings.warningList.length > 0;

    const setTabState = value => {
      this.setState({ tab: value });
    };

    return (
      <div className="measurementTable">
        <div className="measurementTableHeader flex justify-between">
          {hasOverallWarnings && (
            <OverlayTrigger
              key={'overwall-warning'}
              placement="left"
              overlay={
                <Tooltip
                  placement="left"
                  className="in tooltip-warning"
                  id="tooltip-left"
                  style={{}}
                >
                  <div className="warningTitle">
                    {t('Criteria nonconformities')}
                  </div>
                  <div className="warningContent">
                    {this.getWarningContent()}
                  </div>
                </Tooltip>
              }
            >
              <span className="warning-status">
                <span className="warning-border">
                  <Icon name="exclamation-triangle" />
                </span>
              </span>
            </OverlayTrigger>
          )}
          {this.getTimepointsHeader()}
          <div className="mt-3 mr-3">
            <button onClick={this.closeTable}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#fff"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex mx-3">
          <span
            title="Measurements"
            className={`tab flex flex-col items-center justify-center ${
              this.state.tab == false ? 'isactive' : ''
            }`}
            onClick={() => setTabState(false)}
          >
            <span>
              <Icon
                style={
                  this.state.tab == false
                    ? { stroke: '#9ccef9', fill: '#9ccef9' }
                    : {}
                }
                name="measurement"
                width="25px"
                height="25px"
              />
            </span>
          </span>
          <span
            title="Findings"
            className={`tab flex flex-col items-center justify-center ${
              this.state.tab == true ? 'isactive' : ''
            }`}
            onClick={() => setTabState(true)}
          >
            <span>
              <Icon
                style={
                  this.state.tab == true
                    ? { stroke: '#9ccef9', fill: '#9ccef9' }
                    : {}
                }
                name="findings2"
                width="25px"
                height="25px"
              />
            </span>
          </span>
        </div>
        <ScrollableArea isMargin={this.state.tab}>
          {this.state.tab == false ? (
            <div>{this.getMeasurementsGroups()}</div>
          ) : (
            <div>{this.getDescriptions()}</div>
          )}
        </ScrollableArea>
        <div className="measurementTableFooter">
          {saveFunction && (
            <button
              onClick={this.saveFunction}
              className="saveBtn flex"
              data-cy="save-measurements-btn"
            >
              {this.state.saveLoader == true ? (
                this.spinner()
              ) : (
                <Icon name="save" width="14px" height="14px" className="mt-1" />
              )}
              <span className="ml-1 ">Save measurements</span>
            </button>
          )}
        </div>
      </div>
    );
  }

  saveFunction = async event => {
    const { saveFunction, onSaveComplete } = this.props;
    this.setState({ saveLoader: true });
    const toolState = cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();
    localStorage.setItem('toolState', JSON.stringify(toolState));
    if (saveFunction) {
      try {
        const result = await saveFunction();
        if (onSaveComplete) {
          this.setState({ saveLoader: false });
          onSaveComplete({
            title: 'STOW SR',
            message: result.message,
            type: 'success',
          });
        }
      } catch (error) {
        if (onSaveComplete) {
          this.setState({ saveLoader: false });
          onSaveComplete({
            title: 'STOW SR',
            message: error.message,
            type: 'error',
          });
        }
      }
    }
  };

  getDescriptions = () => {
    return (
      <Findings
        measurements={this.state.updatedMeasurement}
        onItemClick={this.props.onItemClick}
        description={this.state.description}
        setDescription={this.setDescription}
        setLoading={this.setLoading}
        isLoading={this.state.isLoading}
      />
    );
  };

  getMeasurementsGroups = () => {
    return this.props.measurementCollection.map((measureGroup, index) => {
      return (
        <TableList
          key={index}
          customHeader={this.getCustomHeader(measureGroup)}
        >
          {this.getMeasurements(measureGroup)}
        </TableList>
      );
    });
  };

  getMeasurements = measureGroup => {
    const selectedKey = this.props.selectedMeasurementNumber
      ? this.props.selectedMeasurementNumber
      : this.state.selectedKey;
    return measureGroup.measurements.map((measurement, index) => {
      const key = measurement.measurementNumber;
      const itemIndex = measurement.itemNumber || index + 1;
      const itemClass =
        selectedKey === key && !this.props.readOnly ? 'selected' : '';

      return (
        <MeasurementTableItem
          key={key}
          itemIndex={itemIndex}
          itemClass={itemClass}
          measurementData={measurement}
          onItemClick={this.onItemClick}
          onRelabel={this.props.onRelabelClick}
          onDelete={this.props.onDeleteClick}
          onHide={this.props.onHideClick}
          onUnhide={this.props.onUnhideClick}
          onEditDescription={this.props.onEditDescriptionClick}
        />
      );
    });
  };

  onItemClick = (event, measurementData) => {
    if (this.props.readOnly) return;

    this.setState({
      selectedKey: measurementData.measurementNumber,
    });

    if (this.props.onItemClick) {
      this.props.onItemClick(event, measurementData);
    }
  };

  getCustomHeader = measureGroup => {
    return (
      <React.Fragment>
        <div className="tableListHeaderTitle">
          {this.props.t(measureGroup.groupName)}
        </div>
        {measureGroup.maxMeasurements && (
          <div className="maxMeasurements">
            {this.props.t('MAX')} {measureGroup.maxMeasurements}
          </div>
        )}
        <div className="numberOfItems">{measureGroup.measurements.length}</div>
      </React.Fragment>
    );
  };

  getTimepointsHeader = () => {
    const { timepoints, t } = this.props;

    return timepoints.map((timepoint, index) => {
      return (
        <div key={index} className="measurementTableHeaderItem">
          <div className="timepointLabel">{t(timepoint.key)}</div>
          <div className="timepointDate">{timepoint.date}</div>
        </div>
      );
    });
  };

  getWarningContent = () => {
    const { warningList = '' } = this.props.overallWarnings;

    if (Array.isArray(warningList)) {
      const listedWarnings = warningList.map((warn, index) => {
        return <li key={index}>{warn}</li>;
      });

      return <ol>{listedWarnings}</ol>;
    } else {
      return <React.Fragment>{warningList}</React.Fragment>;
    }
  };
}

const connectedComponent = withTranslation(['MeasurementTable', 'Common'])(
  MeasurementTable
);
export { connectedComponent as MeasurementTable };
export default withAppContext(connectedComponent);
