import React from 'react';
import adjust from './icons/adjust.svg';
// Icons
import angleDoubleDown from './icons/angle-double-down.svg';
import angleDoubleUp from './icons/angle-double-up.svg';
import angleLeft from './icons/angle-left.svg';
import arrows from './icons/pan-icon.svg';
import arrowsAltH from './icons/arrows-alt-h.svg';
import arrowsAltV from './icons/arrows-alt-v.svg';
import bars from './icons/bar-icon.svg';
import brain from './icons/brain.svg';
import brush from './icons/brush.svg';
import caretDown from './icons/caret-down.svg';
import marker from './icons/marker.svg';
import compare from './icons/compare.svg';
import deleteicon from './icons/deleteicon.svg';

import caretUp from './icons/caret-up.svg';
import check from './icons/check.svg';
import checkCircle from './icons/check-circle.svg';
import checkCircleO from './icons/check-circle-o.svg';
import chevronDown from './icons/chevron-down.svg';
import circle from './icons/circle.svg';
import circleNotch from './icons/circle-notch.svg';
import circleO from './icons/circle-o.svg';
import volumeperc from './icons/volumeperc.svg';
import brightnesss from './icons/brightness.svg';
import savedPreset from './icons/saved-preset.svg';

import clipboard from './icons/clipboard.svg';
import cog from './icons/cog.svg';
import createComment from './icons/create-comment.svg';
import createScreenCapture from './icons/create-screen-capture.svg';
import crosshairs from './icons/crosshairs.svg';
import cube from './icons/cube.svg';
import d3Rotat from './icons/3d-rotate.svg';
import rotation3d1 from './icons/3d-rotation.svg';
import d2ico from './icons/2d.svg';
import database from './icons/database.svg';
import dotCircle from './icons/dot-circle.svg';
import edit from './icons/edit.svg';
import spine from './icons/spine.svg';
import ellipseCircle1 from './icons/ellipse-circle.svg';
import ellipseH from './icons/ellipse-h.svg';
import ellipseV from './icons/ellipse-v.svg';
import exclamationCircle from './icons/exclamation-circle.svg';
import exclamationTriangle from './icons/exclamation-triangle.svg';
import fastBackward from './icons/fast-backward.svg';
import fastForward from './icons/fast-forward.svg';
import stop from './icons/stop.svg';
import info from './icons/info.svg';
import inlineEdit from './icons/inline-edit.svg';
// import level from './icons/level.svg';
import levell from './icons/level-icon.svg';
import importIcon from './icons/import.svg';
import cloudUploadIcon from './icons/cloud-upload.svg';
import awsBrands from './icons/aws-brands.svg';
import googleIcon from './icons/google_drive.svg';
import link from './icons/link.svg';
import linkCircles from './icons/link-circles.svg';
import list from './icons/list.svg';
import liver from './icons/liver.svg';
import lock from './icons/lock.svg';
import lockAlt from './icons/lock-alt.svg';
import lung from './icons/lung.svg';
import measureNonTarget from './icons/measure-non-target.svg';
import measureTarget from './icons/measure-target.svg';
import measureTargetCr from './icons/measure-target-cr.svg';
import measureTargetNe from './icons/measure-target-ne.svg';
import measureTargetUn from './icons/measure-target-un.svg';
import measurement1 from './icons/measurement.svg';
import measureTemp1 from './icons/measure-temp.svg';
import cobbAngle1 from './icons/cobb-angle.svg';
import objectGroup from './icons/object-group.svg';
import dcmcloudLogo from './icons/dcmcloud-logo.svg';
import forward from './icons/forward.svg';
import backward from './icons/backward.svg';
import help from './icons/help.svg';
import undo from './icons/undo.svg';
import redo from './icons/redo.svg';
import preference from './icons/preference.svg';
import dcmcloudTextLogo from './icons/dcmcloud-text-logo.svg';
import oval from './icons/oval.svg';
import clearall from './icons/clearall.svg';
import palette from './icons/palette.svg';
import play from './icons/play.svg';
import plus from './icons/plus.svg';
import findings2 from './icons/findings.svg';
import powerOff from './icons/power-off.svg';
import reset3 from './icons/reset.svg';
import rotate from './icons/rotate.svg';
import rotateRight from './icons/rotate-right.svg';
import saveRegular from './icons/save-regular.svg';
import scissors from './icons/scissors.svg';
import search from './icons/search.svg';
import searchPlus from './icons/search-plus.svg';
import softTissue from './icons/soft-tissue.svg';
import sort from './icons/sort.svg';
import theme from './icons/theme.svg';
import sortDown from './icons/sort-down.svg';
import sortUp from './icons/sort-up.svg';
import sphere from './icons/sphere.svg';
import squareO from './icons/square-o.svg';
import star from './icons/star.svg';
import stepBackward from './icons/step-backward.svg';
import stepForward from './icons/step-forward.svg';
import sun from './icons/sun.svg';
import th from './icons/grid.svg';
import thLarge from './icons/th-large.svg';
import thList from './icons/th-list.svg';
import times from './icons/times.svg';
import cardio from './icons/cardio.svg';
import trash1 from './icons/trash.svg';
import unlink from './icons/unlink.svg';
import user from './icons/user.svg';
import youtube from './icons/youtube.svg';
import eye from './icons/eye.svg';
import eyeClosed from './icons/eye-closed.svg';
import envelopeSquare from './icons/envelope-square.svg';
import dciLogo from './icons/header-logo.svg';
import setting from './icons/setting.svg';
import scale1 from './icons/scale.svg';
import magnify from './icons/magnify.svg';
import window from './icons/window.svg';
import eraser from './icons/eraser.svg';
import upload from './icons/upload.svg';
import dentalProbe from './icons/dental-probe.svg';
import ellipse from './icons/ellipse.svg';
import rectangular from './icons/rectangular.svg';
import invert from './icons/invert.svg';
import rotateR from './icons/rotate-r.svg';
import flipV from './icons/flip-v.svg';
import flipH from './icons/flip-h.svg';
import transfer from './icons/transfer.svg';
import annotatenew from './icons/annotatenew.svg';
import color from './icons/color.svg';
import grayscale from './icons/grayscale.svg';
import delete2 from './icons/delete2.svg';
import xray from './icons/xray.svg';
import image1 from './icons/image.svg';
import clear from './icons/clear.svg';
import ai from './icons/aistar.svg';
import dcmViewerLogo from './icons/dcm-logo-canvas.svg';
import dcmBin from './icons/delete.svg';
import zoom1 from './icons/Zoom.svg';
import freehand from './icons/freehand.svg';
import Filterr from './icons/Filter.svg';
import More from './icons/more.svg';
import Anotate1 from './icons/Anotate.svg';
import home from './icons/home.svg';
import dashboard from './icons/dashboard.svg';
import Aws1 from './icons/Aws1.svg';
import Google1 from './icons/Google1.svg';
import Studylist from './icons/Studylist.svg';
import pacx from './icons/pacx.svg';
import cUpload from './icons/cUpload.svg';
import storage from './icons/storage.svg';
import series1 from './icons/series-info-1.svg';
import series2 from './icons/series-info-2.svg';
import series3 from './icons/series-info-3.svg';
import sidepanel from './icons/sidepanel.svg';
import sidepanel2 from './icons/sidepanel2.svg';
import Camera from './icons/Camera.svg';
import bone from './icons/bone.svg';
import next from './icons/next.svg';
import previous from './icons/previous.svg';
import Handd from './icons/Hand.svg';
import tag from './icons/tag.svg';
import Layout from './icons/Layout.svg';
import PatientI from './icons/Patient-info.svg';
import Cloud2 from './icons/Cloud-up.svg';
import local2 from './icons/local2.svg';
import { cloudConnection } from './icons/cloud_connection.svg';
import { share } from './icons/share-2.svg';
import { cloud } from './icons/cloud-upload.svg';
import mylogo from './icons/dcmcloud-logo.svg';
import loader from './icons/loader.svg';
import relabel from './icons/relabel.svg';
import download from './icons/Dow.svg';
import ann from './icons/ann.svg';
import editIcon from './icons/edit-icon.svg';
import synchronizer from './icons/synchronizer.svg';
import reports from './icons/reports.svg';
import logout from './icons/logout.svg';
// import ann2 from './icons/ann2.svg';

const ICONS = {
  logout,
  clearall,
  reports,
  home,
  dashboard,
  mylogo,
  download,
  loader,
  Aws1,
  Google1,
  local2,
  pacx,
  Studylist,
  cloud,
  cUpload,
  storage,
  tag,
  upload,
  ai,
  cobbAngle: cobbAngle1,
  previous,
  next,
  share,
  cardio,
  sidepanel2,
  cloudConnection,
  series1,
  help,
  series2,
  series3,
  sidepanel,
  compare,
  Cloud2,
  Camera,
  PatientI,
  Hand: Handd,
  eye,
  'eye-closed': eyeClosed,
  brush,
  'dcm-logo-canvas': dcmViewerLogo,
  'dcm-bin': dcmBin,
  delete2,
  scissors,
  marker,
  bone,
  user,
  sort,
  th,
  star,
  relabel,
  xray,
  forward,
  backward,
  'sort-up': sortUp,
  sphere,
  'sort-down': sortDown,
  info,
  cube,
  crosshairs,
  theme,
  findings2,
  preference,
  annotatenew,
  synchronizer,
  'dot-circle': dotCircle,
  'angle-left': angleLeft,
  '3d-rotate': d3Rotat,
  rotation3d: rotation3d1,
  icon3d: d2ico,
  plus,
  'chevron-down': chevronDown,
  'angle-double-down': angleDoubleDown,
  'angle-double-up': angleDoubleUp,
  'arrows-alt-h': arrowsAltH,
  'arrows-alt-v': arrowsAltV,
  bars,
  deleteicon,
  measurement: measurement1,
  'caret-down': caretDown,
  'caret-up': caretUp,
  'check-circle-o': checkCircleO,
  'saved-preset': savedPreset,
  check,
  spine,
  circle,
  reset: reset3,
  volumeperc,
  brightness: brightnesss,
  'circle-o': circleO,
  times,
  Filter: Filterr,
  More,
  eraser,
  ann,
  // ann2,
  Anotate: Anotate1,
  Layout,
  'create-comment': createComment,
  'create-screen-capture': createScreenCapture,
  edit,
  'fast-backward': fastBackward,
  'fast-forward': fastForward,
  'object-group': objectGroup,
  search,
  'power-off': powerOff,
  'inline-edit': inlineEdit,
  list,
  'dcmcloud-logo': dcmcloudLogo,
  'dci-logo': dciLogo,
  setting,
  scale: scale1,
  redo,
  undo,
  freehand,
  'dcmcloud-text-logo': dcmcloudTextLogo,
  lock,
  play,
  database,
  cog,
  'circle-notch': circleNotch,
  'square-o': squareO,
  'check-circle': checkCircle,
  'lock-alt': lockAlt,
  'step-backward': stepBackward,
  'step-forward': stepForward,
  clipboard: clipboard,
  stop,
  'th-large': thLarge,
  'th-list': thList,
  sun,
  palette,
  'import-icon': importIcon,
  'aws-icon': awsBrands,
  'google-icon': googleIcon,
  'cloud-upload-icon': cloudUploadIcon,
  youtube,
  oval,
  'ellipse-h': ellipseH,
  'ellipse-v': ellipseV,
  adjust,
  level: levell,
  zoom: zoom1,
  'link-circles': linkCircles,
  'search-plus': searchPlus,
  'measure-non-target': measureNonTarget,
  'measure-target': measureTarget,
  'measure-target-cr': measureTargetCr,
  'measure-target-un': measureTargetUn,
  'measure-target-ne': measureTargetNe,
  'measure-temp': measureTemp1,
  'ellipse-circle': ellipseCircle1,
  arrows,
  rotate,
  'rotate-right': rotateRight,
  trash: trash1,
  unlink,
  'exclamation-circle': exclamationCircle,
  link,
  'exclamation-triangle': exclamationTriangle,
  brain,
  'soft-tissue': softTissue,
  lung,
  liver,
  save: saveRegular,
  'envelope-square': envelopeSquare,
  magnify,
  window,
  'dental-probe': dentalProbe,
  ellipse,
  rectangular,
  invert,
  'rotate-r': rotateR,
  'flip-v': flipV,
  'flip-h': flipH,
  'color-lut': color,
  'gray-scale': grayscale,
  transfer,
  image: image1,
  clear,
  'edit-icon': editIcon,
};

/**
 * Return the matching SVG Icon as a React Component.
 * Results in an inlined SVG Element. If there's no match,
 * return `null`
 */
export default function getIcon(key, props) {
  if (!key || !ICONS[key]) {
    // console.log(key, props, 'newwwwww');
    return React.createElement('div', null, 'Missing Icon');
  }

  return React.createElement(ICONS[key], props);
}

export { ICONS };
