import DCMCloud from '@dcmcloud/core';
import { SimpleDialog } from '@dcmcloud/ui';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import csTools from 'cornerstone-tools';
import merge from 'lodash.merge';
import initCornerstoneTools from './initCornerstoneTools.js';
import measurementServiceMappingsFactory from './utils/measurementServiceMappings/measurementServiceMappingsFactory';
import { createRef, useEffect, useState, useRef } from 'react';
import html2canvas from 'html2canvas';
// import annotations from '../../../platform/core/src/redux/reducers/anotations.js';
import cogoToast from 'cogo-toast';
import CTRRatioTool from './CTRToolUpdated.js';
import { KeyImages } from '../../../Constants/baseURL.js';
// import useRef from 'react';

/**
 *
 * @param {Object} servicesManager
 * @param {Object} configuration
 * @param {Object|Array} configuration.csToolsConfig
 */

export default function init({ servicesManager, configuration }) {

  function getKey() {
    var urlArray = window.location.href.split('/');
    // console.log(urlArray, 'urlArrayurlArray');

    if (urlArray.length > 5) {
      var keyTypeArray = urlArray[urlArray.length - 1];
      var Xrad = urlArray[urlArray.length - 3];
      var XradFs = urlArray[urlArray.length - 4];
      var keyFolder = urlArray[urlArray.length - 2];
      var moreSplit =
        XradFs +
        '/' +
        Xrad +
        '/' +
        keyFolder +
        '/' +
        keyTypeArray.split('%5')[0];
      // console.log(
      //   keyFolder,
      //   'keyFolder',
      //   keyTypeArray,
      //   ' keyTypeArray',
      //   moreSplit,
      //   'keyTypeArray'
      // );
    } else {
      var keyTypeArray = urlArray[urlArray.length - 1];
      var moreSplit = keyTypeArray.split('%5')[0];
      // console.log(moreSplit, 'moresplit');
    }
    return moreSplit;
  }

  // const postAnotation = async signInData => {
  //   setLoader(true);
  //   var requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: signInData,
  //   };
  //   const hello = await fetch(
  //     `https://dcm-cloud.herokuapp.com/api/v1/users/single`,
  //     requestOptions
  //   );
  //   const resp = await hello.json();
  //   console.log(resp);
  //   if (resp.success == 'true') {
  //     // console.log('resp.success')
  //     setLoader(false);
  //     localStorage.setItem('userData', JSON.stringify(resp.user));
  //     localStorage.setItem('token', resp.token);
  //     if (resp.user.stripeId) {
  //       history.push('/studylist');
  //     } else {
  //       history.push('/package');
  //     }
  //     // history.push('/studylist')

  //     Swal.fire({
  //       title: 'success',
  //       icon: 'success',
  //       confirmButtonColor: '#ed2a26',
  //     });
  //   } else {
  //     Swal.fire({
  //       text: resp.error,
  //       icon: 'error',
  //       confirmButtonColor: '#ed2a26',
  //     });
  //   }
  // };
  // const dispatch = useDispatch();
  // const ImageId = localStorage.getItem('ImageId');
  // const data = updatedData[ImageId]
  // console.log(data, "datadataImage")
  const { UIDialogService, MeasurementService } = servicesManager.services;
  //const [saving, setSaving] = useState(null);
  let Bucpath;
  let ImageID;
  let immage;
  // window.addEventListener('indexUpdated', (event) => {
  //   const index = event.detail;
  //   console.log(index,"indexxxxxxxx"); });

  window.addEventListener('dataUpdated', event => {
    // Retrieve the updated data from the event from retrieveMetadataLoader
    const updatedData = event.detail;
    // console.log(updatedData, 'updateddddd');
    const imageIdsArray = [];
    updatedData.forEach(dataItem => {
      Bucpath = dataItem.slice(0, dataItem.lastIndexOf('/'));
      const valuesArray = dataItem.split('/');
      ImageID = valuesArray[valuesArray.length - 1];
      imageIdsArray.push(ImageID);
      //setSaving(ImageID);
      //  console.log("ImageID:", ImageID);
      // console.log('Path:', Bucpath);
      window.addEventListener('indexUpdated', event => {
        const index = event.detail;
        // console.log(index, 'indexxxxxxxx');
        immage = imageIdsArray[index];
        // console.log(immage, 'immageeeee');
      });
      //        const index = localStorage.getItem("CI")
      // console.log(index,"indexxxxx");
      //        const immage = imageIdsArray[index];
      //        console.log(immage,"immageeeee");
      //const Id = localStorage.setItem("ImageID:")
      //console.log("ImageID:", imageIdsArray);
      //localStorage.setItem("ImageID:", imageIdsArray)
      // await handleCaptureScreenshot(Bucpath, ImageID);
      // imageData
      // const ImageIndex = localStorage.getItem('ImageId');
      //   const [state,setState] =useState({});
      // console.log(state,"statestate");

      // let imageData = {
      //   ImageIndex: ImageIndex,
      //   ImageID: ImageID
      // };
      // setState(imageData);
      // console.log(imageData, "imageDataimageData");
    });
    // console.log(imageIdsArray, 'imageIdsArrayyyyy');
  });

  window.addEventListener('customEvent', (event, eventDetails, callback) => {
    const detail = event.detail;
    // console.log(detail, 'detaildetail');
    // const result = event.detail.des;
    callInputDialog(null, eventDetails, callback);
    setTimeout(() => {
      handleCaptureScreenshot(detail, ImageID);
    }, 50);
    // console.log(detail, 'annotationannotation123');
  });


  const handleCaptureScreenshot = async (value, ImageID) => {

    const result = value.des;
    // console.log(result, 'resultresult');
    const val = value.message;
    // console.log(val, 'valval');
    const string = value.fullstring;
    const rootElement = document.getElementById('root');
    // console.log(rootElement, 'rootElement');
    let mainContentElement;
    if (rootElement) {
      mainContentElement = rootElement.querySelector('.cornerstone-canvas');
    }
    try {
      const canvas = await html2canvas(mainContentElement);
      const updateEvent = new CustomEvent('keyUpdated', {
        detail: 'loading',
      });
      window.dispatchEvent(updateEvent);
      const dataUrl = canvas.toDataURL();
      const token = localStorage.getItem('token');
      const label = {
        AwsAccessKey: 'AKIAQOPLK6GF3AKQQMEJ',
        AwsSecretAccessKey: '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',
        AwsBucket: 'dcmcloud-demo',
        suid: getKey(),
      };
      const fromdata = {
        label: val,
        AwsAccessKey: 'AKIAQOPLK6GF3AKQQMEJ',
        AwsSecretAccessKey: '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',
        AwsBucket: 'dcmcloud-demo',
        suid: getKey(),
        description: string,
      };
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          label: val,
          AwsAccessKey: 'AKIAQOPLK6GF3AKQQMEJ',
          AwsSecretAccessKey: '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',
          AwsBucket: 'dcmcloud-demo',
          // des: result,
          base64Image: dataUrl,
          suid: getKey(),
        }),
      };

      fetch(
        `${KeyImages}/keyImages/v1/annotationNode/anotation`,
        requestOptions
      )
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          // Handle response data
          // console.log(data);
          return fetch(
            `${KeyImages}/keyImages/v1/annotationNode/postDescription`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(fromdata),
            }
          );
        })
        .then(data => {
          const updateEvent = new CustomEvent('keyUpdated', {
            detail: 'false',
          });
          // console.log(updateEvent, 'updateEventupdateEvent');
          window.dispatchEvent(updateEvent);
        })
        .catch(error => {
          console.error(
            'There was a problem with your fetch operation:',
            error
          );
        });
    } catch (error) {
      console.error(error);
    }
  };


  const callInputDialog = (data, event, callback) => {
    // console.log('Accepted Files in init component:', data);
    // if (UIDialogService) {
    //   console.log(UIDialogService, 'UIDialogService');
    //   let dialogId = UIDialogService.create({
    //     centralize: true,
    //     isDraggable: false,
    //     content: SimpleDialog.InputDialog,
    //     useLastPosition: false,
    //     showOverlay: true,
    //     contentProps: {
    //       title: 'Enter your annotation',
    //       label: 'New label',
    //       measurementData: data ? { description: data.text } : {},
    //       onClose: () => UIDialogService.dismiss({ id: dialogId }),
    //       onSubmit: async value => {
    //         const words = value.split(' ');
    //         // console.log(words,"newwwwwwwwww");
    //         // Filter words containing "@" sign
    //         // const wordsWithAt = words.filter(word => word.includes('@'));
    //         const wordsWithAt = words
    //           .map(word => {
    //             if (word.includes('@')) {
    //               // Remove "@" symbol from the word
    //               return word.replace('@', '');
    //             }
    //             return word; // Return the word unchanged
    //           })
    //           .filter(word => word !== '');

    //         const remaining = words.filter(word => !word.includes('@'));

    //         const updateEvent = new CustomEvent('loader', {
    //           detail: wordsWithAt[0],
    //         });
    //         window.dispatchEvent(updateEvent);
    //         callback(wordsWithAt[0]);

    //         UIDialogService.dismiss({ id: dialogId });
    //         setTimeout(() => {
    //           handleCaptureScreenshot(value, ImageID);
    //         }, 50);
    //       },
    //     },
    //   });
    // }
    const { csToolsConfig } = configuration;
    const metadataProvider = DCMCloud.cornerstone.metadataProvider;

    cornerstone.metaData.addProvider(
      metadataProvider.get.bind(metadataProvider),
      9999
    );

    // ~~
    const defaultCsToolsConfig = csToolsConfig || {
      globalToolSyncEnabled: true,
      showSVGCursors: true,
      autoResizeViewports: false,
    };

    initCornerstoneTools(defaultCsToolsConfig);

    const toolsGroupedByType = {
      touch: [csTools.PanMultiTouchTool, csTools.ZoomTouchPinchTool],
      annotations: [
        csTools.ArrowAnnotateTool,
        csTools.BidirectionalTool,
        csTools.LengthTool,
        csTools.AngleTool,
        csTools.FreehandRoiTool,
        csTools.EllipticalRoiTool,
        csTools.ProbeTool,
        csTools.TextMarkerTool,
        csTools.DragProbeTool,
        csTools.RectangleRoiTool,
      ],
      other: [
        csTools.PanTool,
        csTools.ZoomTool,
        csTools.WwwcTool,
        csTools.RotateTool,
        csTools.WwwcRegionTool,
        csTools.MagnifyTool,
        csTools.StackScrollTool,
        csTools.StackScrollMouseWheelTool,
        csTools.OverlayTool,
      ],
    };

    let tools = [];
    Object.keys(toolsGroupedByType).forEach(toolsGroup =>
      tools.push(...toolsGroupedByType[toolsGroup])
    );

    /* Measurement Service */
    _connectToolsToMeasurementService(MeasurementService);

    /* Add extension tools configuration here. */
    const internalToolsConfig = {
      ArrowAnnotate: {
        configuration: {
          // getTextCallback: (callback, eventDetails) =>
          //   callInputDialog(null, eventDetails, callback),
          changeTextCallback: (data, eventDetails, callback) =>
            callInputDialog(data, eventDetails, callback),
        },
      },
    };

    /* Abstract tools configuration using extension configuration. */
    const parseToolProps = (props, tool) => {
      const { annotations } = toolsGroupedByType;
      // An alternative approach would be to remove the `drawHandlesOnHover` config
      // from the supported configuration properties in `cornerstone-tools`
      const toolsWithHideableHandles = annotations.filter(
        tool => !['RectangleRoiTool', 'EllipticalRoiTool'].includes(tool.name)
      );

      let parsedProps = { ...props };

      /**
       * drawHandles - Never/Always show handles
       * drawHandlesOnHover - Only show handles on handle hover (pointNearHandle)
       *
       * Does not apply to tools where handles aren't placed in predictable
       * locations.
       */
      if (
        configuration.hideHandles !== false &&
        toolsWithHideableHandles.includes(tool)
      ) {
        if (props.configuration) {
          parsedProps.configuration.drawHandlesOnHover = true;
        } else {
          parsedProps.configuration = { drawHandlesOnHover: true };
        }
      }

      return parsedProps;
    };

    /* Add tools with its custom props through extension configuration. */
    tools.forEach(tool => {
      const toolName = tool.name.replace('Tool', '');
      const externalToolsConfig = configuration.tools || {};
      const externalToolProps = externalToolsConfig[toolName] || {};
      const internalToolProps = internalToolsConfig[toolName] || {};
      const props = merge(
        internalToolProps,
        parseToolProps(externalToolProps, tool)
      );
      console.log(tool);
      csTools.addTool(tool, props);
      csTools.addTool(CTRRatioTool);
    });

    // TODO -> We need a better way to do this with maybe global tool state setting all tools passive.
    const BaseAnnotationTool = csTools.importInternal(
      'base/BaseAnnotationTool'
    );
    tools.forEach(tool => {
      if (tool.prototype instanceof BaseAnnotationTool) {
        // BaseAnnotationTool would likely come from csTools lib exports
        const toolName = new tool().name;
        csTools.setToolPassive(toolName); // there may be a better place to determine name; may not be on uninstantiated class
      }
    });

    csTools.setToolActive('Pan', { mouseButtonMask: 4 });
    csTools.setToolActive('Zoom', { mouseButtonMask: 2 });
    csTools.setToolActive('Wwwc', { mouseButtonMask: 1 });
    csTools.setToolActive('CTRRatioTool', { mouseButtonMask: 1 });
    csTools.setToolActive('Rotate', { mouseButtonMask: 1 });
    csTools.setToolActive('StackScrollMouseWheel', {}); // TODO: Empty options should not be required
    csTools.setToolActive('PanMultiTouch', { pointers: 2 }); // TODO: Better error if no options
    csTools.setToolActive('ZoomTouchPinch', {});
    csTools.setToolEnabled('Overlay', {});
  };

  const { csToolsConfig } = configuration;
  const metadataProvider = DCMCloud.cornerstone.metadataProvider;

  cornerstone.metaData.addProvider(
    metadataProvider.get.bind(metadataProvider),
    9999
  );

  // ~~
  const defaultCsToolsConfig = csToolsConfig || {
    globalToolSyncEnabled: true,
    showSVGCursors: true,
    autoResizeViewports: false,
  };

  initCornerstoneTools(defaultCsToolsConfig);

  const toolsGroupedByType = {
    touch: [csTools.PanMultiTouchTool, csTools.ZoomTouchPinchTool],
    annotations: [
      csTools.ArrowAnnotateTool,
      csTools.BidirectionalTool,
      csTools.LengthTool,
      csTools.AngleTool,
      csTools.FreehandRoiTool,
      csTools.EllipticalRoiTool,
      csTools.ProbeTool,
      csTools.TextMarkerTool,
      csTools.DragProbeTool,
      csTools.RectangleRoiTool,
      csTools.CobbAngleTool,
      csTools.BrushTool,
      csTools.ScaleOverlayTool,
      csTools.EraserTool,
      csTools.CircleScissorsTool,
      csTools.FreehandScissorsTool,
      csTools.RectangleScissorsTool,
    ],
    other: [
      csTools.PanTool,
      csTools.ZoomTool,
      csTools.WwwcTool,
      csTools.RotateTool,
      csTools.WwwcRegionTool,
      csTools.MagnifyTool,
      csTools.StackScrollTool,
      csTools.StackScrollMouseWheelTool,
      csTools.OverlayTool,
    ],
  };

  let tools = [];
  Object.keys(toolsGroupedByType).forEach(toolsGroup =>
    tools.push(...toolsGroupedByType[toolsGroup])
  );

  /* Measurement Service */
  _connectToolsToMeasurementService(MeasurementService);

  /* Add extension tools configuration here. */
  const internalToolsConfig = {
    ArrowAnnotate: {
      configuration: {
        // getTextCallback: (callback, eventDetails) =>
        //   callInputDialog(null, eventDetails, callback),
        changeTextCallback: (data, eventDetails, callback) =>
          callInputDialog(data, eventDetails, callback),
      },
    },
  };

  /* Abstract tools configuration using extension configuration. */
  const parseToolProps = (props, tool) => {
    const { annotations } = toolsGroupedByType;
    // An alternative approach would be to remove the `drawHandlesOnHover` config
    // from the supported configuration properties in `cornerstone-tools`
    const toolsWithHideableHandles = annotations.filter(
      tool => !['RectangleRoiTool', 'EllipticalRoiTool'].includes(tool.name)
    );

    let parsedProps = { ...props };

    /**
     * drawHandles - Never/Always show handles
     * drawHandlesOnHover - Only show handles on handle hover (pointNearHandle)
     *
     * Does not apply to tools where handles aren't placed in predictable
     * locations.
     */
    if (
      configuration.hideHandles !== false &&
      toolsWithHideableHandles.includes(tool)
    ) {
      if (props.configuration) {
        parsedProps.configuration.drawHandlesOnHover = true;
      } else {
        parsedProps.configuration = { drawHandlesOnHover: true };
      }
    }

    return parsedProps;
  };

  /* Add tools with its custom props through extension configuration. */
  tools.forEach(tool => {
    const toolName = tool.name.replace('Tool', '');
    const externalToolsConfig = configuration.tools || {};
    const externalToolProps = externalToolsConfig[toolName] || {};
    const internalToolProps = internalToolsConfig[toolName] || {};
    const props = merge(
      internalToolProps,
      parseToolProps(externalToolProps, tool)
    );
    csTools.addTool(tool, props);
    csTools.addTool(CTRRatioTool);
  });

  // TODO -> We need a better way to do this with maybe global tool state setting all tools passive.
  const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');
  tools.forEach(tool => {
    if (tool.prototype instanceof BaseAnnotationTool) {
      // BaseAnnotationTool would likely come from csTools lib exports
      const toolName = new tool().name;
      csTools.setToolPassive(toolName); // there may be a better place to determine name; may not be on uninstantiated class
    }
  });

  csTools.setToolActive('CobbAngle', { mouseButtonMask: 1 });
  csTools.setToolActive('Brush', { mouseButtonMask: 1 });
  csTools.setToolActive('CircleScissors', { mouseButtonMask: 1 });
  csTools.setToolActive('FreehandScissors', { mouseButtonMask: 1 });
  csTools.setToolActive('RectangleScissors', { mouseButtonMask: 1 });
  csTools.setToolActive('Pan', { mouseButtonMask: 4 });
  csTools.setToolActive('Zoom', { mouseButtonMask: 2 });
  csTools.setToolActive('CTRRatioTool', { mouseButtonMask: 1 });
  csTools.setToolActive('Rotate', { mouseButtonMask: 1 });
  csTools.setToolActive('Wwwc', { mouseButtonMask: 1 });
  csTools.setToolActive('StackScrollMouseWheel', {}); // TODO: Empty options should not be required
  csTools.setToolActive('PanMultiTouch', { pointers: 2 }); // TODO: Better error if no options
  csTools.setToolActive('ZoomTouchPinch', {});
  csTools.setToolEnabled('Overlay', {});
}

const _initMeasurementService = measurementService => {
  /* Initialization */
  const {
    toAnnotation,
    toMeasurement,
    factories,
  } = measurementServiceMappingsFactory(measurementService);
  const csToolsVer4MeasurementSource = measurementService.createSource(
    'CornerstoneTools',
    '4'
  );

  /* Matching Criterias */
  const matchingCriteria = {
    valueType: measurementService.VALUE_TYPES.POLYLINE,
    points: 2,
  };

  /* Mappings */
  measurementService.addMapping(
    csToolsVer4MeasurementSource,
    'Length',
    matchingCriteria,
    toAnnotation,
    toMeasurement
  );

  return csToolsVer4MeasurementSource;
};

const _connectToolsToMeasurementService = measurementService => {
  const csToolsVer4MeasurementSource = _initMeasurementService(
    measurementService
  );
  const {
    id: sourceId,
    addOrUpdate,
    getAnnotation,
  } = csToolsVer4MeasurementSource;

  /* Measurement Service Events */
  cornerstone.events.addEventListener(
    cornerstone.EVENTS.ELEMENT_ENABLED,
    event => {
      const {
        MEASUREMENT_ADDED,
        MEASUREMENT_UPDATED,
      } = measurementService.EVENTS;

      measurementService.subscribe(
        MEASUREMENT_ADDED,
        ({ source, measurement }) => {
          if (![sourceId].includes(source.id)) {
            const annotation = getAnnotation('Length', measurement.id);

            // console.log(
            //   'Measurement Service [Cornerstone]: Measurement added',
            //   measurement
            // );
            // console.log('Mapped annotation:', annotation);
          }
        }
      );

      measurementService.subscribe(
        MEASUREMENT_UPDATED,
        ({ source, measurement }) => {
          if (![sourceId].includes(source.id)) {
            const annotation = getAnnotation('Length', measurement.id);

            // console.log(
            //   'Measurement Service [Cornerstone]: Measurement updated',
            //   measurement
            // );
            // console.log('Mapped annotation:', annotation);
          }
        }
      );

      const addOrUpdateMeasurement = csToolsAnnotation => {
        try {
          const { toolName, toolType, measurementData } = csToolsAnnotation;
          // console.log(csToolsAnnotation, 'csToolsAnnotation');
          const csTool = toolName || measurementData.toolType || toolType;
          csToolsAnnotation.id = measurementData._measurementServiceId;
          const measurementServiceId = addOrUpdate(csTool, csToolsAnnotation);

          if (!measurementData._measurementServiceId) {
            addMeasurementServiceId(measurementServiceId, csToolsAnnotation);
          }
        } catch (error) {
          console.warn('Failed to add or update measurement:', error);
        }
      };

      const addMeasurementServiceId = (id, csToolsAnnotation) => {
        const { measurementData } = csToolsAnnotation;
        Object.assign(measurementData, { _measurementServiceId: id });
      };

      [
        csTools.EVENTS.MEASUREMENT_ADDED,
        csTools.EVENTS.MEASUREMENT_MODIFIED,
      ].forEach(csToolsEvtName => {
        event.detail.element.addEventListener(
          csToolsEvtName,
          ({ detail: csToolsAnnotation }) => {
            // console.log(`Cornerstone Element Event: ${csToolsEvtName}`);
            addOrUpdateMeasurement(csToolsAnnotation);
          }
        );
      });
    }
  );
};
