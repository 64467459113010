import { asyncComponent, retryImport } from '@dcmcloud/ui';
import DCMCloud from '@dcmcloud/core';

const { urlUtil: UrlUtil } = DCMCloud.utils;

// Dynamic Import Routes (CodeSplitting)
const IHEInvokeImageDisplay = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "IHEInvokeImageDisplay" */ './IHEInvokeImageDisplay.js'
    )
  )
);
const ViewerRouting = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "ViewerRouting" */ './ViewerRouting.js')
  )
);
const ViewerRoutingMrc = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "ViewerRouting" */ './ViewerRouting.js')
  )
);

const ViewerRoutingxrad = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "ViewerRouting" */ './ViewerRouting.js')
  )
);

const ViewerRoutingxrad2 = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "ViewerRouting" */ './ViewerRouting.js')
  )
);

const ViewerRoutingabc = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "ViewerRouting" */ './ViewerRouting.js')
  )
);

const ViewerRoutingxyz = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "ViewerRouting" */ './ViewerRouting.js')
  )
);

const StudyListRouting = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "StudyListRouting" */ '../studylist/StudyListRouting.js'
    )
  )
);
const StandaloneRouting = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "ConnectedStandaloneRouting" */ '../connectedComponents/ConnectedStandaloneRouting.js'
    )
  )
);
const test = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "ConnectedStandaloneRouting" */ '../components/test.js'
    )
  )
);

const ViewerLocalFileData = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "ViewerLocalFileData" */ '../connectedComponents/ViewerLocalFileData.js'
    )
  )
);
const Bar = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "ConnectedStandaloneRouting" */ '../components/progressBar/Bar.jsx'
    )
  )
);
const UserManual = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "ConnectedStandaloneRouting" */ '../components/UserManual'
    )
  )
);
const Login = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "Authenticate" */ '../components/Login')
  )
);
const ShareLogin = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "Authenticate" */ '../components/Login')
  )
);
const Admin = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "ViewerRouting" */ '../components/Admin')
  )
);
const ClientDetail = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "ViewerRouting" */ '../components/ClientDetail')
  )
);
const Home = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "ViewerRouting" */ '../components/Home')
  )
);
const Pacx = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "ViewerRouting" */ '../components/Pacx.js')
  )
);

const SignUp = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "SignUp" */ '../components/SignUp')
  )
);
const SuccessPage = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "SuccessPage" */ '../components/SuccessPage')
  )
);
const PackagePrice = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "PackagePrice" */ '../components/PackagePrice')
  )
);
const AWSAccessKeyFieldsNewPage = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "PackagePrice" */ '../components/AWSAccessKeyFieldsNewPage'
    )
  )
);
const AWSAccessKeyFields = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "PackagePrice" */ '../components/UserPreferences/AWSAccessKeyFields'
    )
  )
);
const AWSAccessKeyForm = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "PackagePrice" */ '../components/AWSAccessKeyForm'
    )
  )
);
const GoogleAccessKeyForm = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "PackagePrice" */ '../components/GoogleAccessKeyForm'
    )
  )
);
const DashboardPage = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "PackagePrice" */ '../components/DashboardPage')
  )
);
const Chart = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "PackagePrice" */ '../components/Chart')
  )
);
const UploadFile = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "PackagePrice" */ '../components/UserPreferences/UploadFile'
    )
  )
);

const DemoLogin = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "PackagePrice" */ '../components/DemoLogin')
  )
);
const ReactComponent = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "PackagePrice" */ '../components/ReactComponent'
    )
  )
);
const AngularComponent = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "PackagePrice" */ '../components/AngularComponent'
    )
  )
);
const HTMLComponent = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "PackagePrice" */ '../components/HTMLComponent')
  )
);
const TemplatePage = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "PackagePrice" */ '../components/TemplatePage')
  )
);

const reload = () => window.location.reload();
// const keyaVal =
//   localStorage.getItem('AwsBucketName') +
//   '^' +
//   localStorage.getItem('AwsAccessKeyId') +
//   '^' +
//   localStorage.getItem('AwsSecretAccessKey');
// const keya = new Buffer.from(keyaVal).toString('base64');
const ROUTES_DEF = {
  default: {
    standaloneViewer: {
      path: '/viewer',
      component: StandaloneRouting,
    },
    viewer: {
      path: '/viewer/:studyInstanceUIDs/',
      component: ViewerRouting,
    },
    viewerMrc: {
      path: '/mrc/:studyInstanceUIDs/',
      component: ViewerRouting,
    },
    viewerRadx: {
      path: '/xrad/:studyInstanceUIDs/',
      component: ViewerRouting,
    },
    viewerExe: {
      path: '/radx/:studyInstanceUIDs/',
      component: ViewerRouting,
    },
    ViewerRoutingabc: {
      path: '/viewer/:studyInstanceUIDs/:folderName',
      component: ViewerRoutingabc,
    },
    ViewerRoutingxyz: {
      // FS/DEMCEN/20221107/1.3.51.0.7.11888149667.7145.21827.44183.57696.53326.20692
      path: '/viewer/:FS/:DEMCEN/:Date/:studyInstanceUIDs',
      component: ViewerRoutingxyz,
    },
    ViewerRoutingxrad0: {
      path: '/xrad/viewer/',
      component: ViewerRoutingxrad,
    },
    ViewerRoutingxrad1: {
      path: '/xrad/viewer/:studyInstanceUIDs',
      component: ViewerRoutingxrad,
    },
    ViewerRoutingxrad2: {
      path: '/xrad/viewer/:studyInstanceUIDs/:folderName',
      component: ViewerRoutingxrad,
    },
    ViewerRoutingxrad3: {
      path: '/xrad/viewer/:FS/:DEMCEN/:Date/:studyInstanceUIDs',
      component: ViewerRoutingxrad,
    },
    ViewerRoutingxrad20: {
      path: '/radx/viewer/',
      component: ViewerRoutingxrad2,
    },
    ViewerRoutingxrad21: {
      path: '/radx/viewer/:studyInstanceUIDs',
      component: ViewerRoutingxrad2,
    },
    ViewerRoutingxrad22: {
      path: '/radx/viewer/:studyInstanceUIDs/:folderName',
      component: ViewerRoutingxrad2,
    },
    ViewerRoutingxrad23: {
      path: '/radx/viewer/:FS/:DEMCEN/:Date/:studyInstanceUIDs',
      component: ViewerRoutingxrad2,
    },
    ViewerRoutingmrc0: {
      path: '/mrc/viewer/',
      component: ViewerRoutingMrc,
    },
    ViewerRoutingmrc1: {
      path: '/mrc/viewer/:studyInstanceUIDs',
      component: ViewerRoutingMrc,
    },
    ViewerRoutingmrc2: {
      path: '/mrc/viewer/:studyInstanceUIDs/:folderName',
      component: ViewerRoutingMrc,
    },
    ViewerRoutingmrc3: {
      path: '/mrc/viewer/:FS/:DEMCEN/:Date/:studyInstanceUIDs',
      component: ViewerRoutingMrc,
    },
    Bar: {
      path: '/signUp',
      component: Bar,
    },
    UserManual: {
      path: '/userManual',
      component: UserManual,
    },

    signIn: {
      path: ['/signIn', '/'],
      component: Login,
    },
    ShareSignIn: {
      path: ['/signInShare/:userName/:pass/:date/:ssuid/:deIdentity/:id'],
      component: ShareLogin,
    },
    AWSAccessKeyForm: {
      path: '/DashboardPage/AWSAccessKey',
      component: AWSAccessKeyForm,
    },
    GoogleAccessKeyForm: {
      path: '/DashboardPage/GoogleAccessKey',
      component: GoogleAccessKeyForm,
    },
    UploadFile: {
      path: '/DashboardPage/UploadFile',
      component: UploadFile,
    },
    Admin: {
      path: '/Dashboard',
      component: Admin,
    },
    ClientDetail: {
      path: '/ClientDetail',
      component: ClientDetail,
    },
    Home: {
      path: '/Home',
      component: Home,
    },
    DemoLogin: {
      path: '/demoLogin',
      component: DemoLogin,
    },
    Pacx: {
      path: '/Pacx',
      component: Pacx,
    },
    test: {
      path: '/test',
      component: test,
    },
    Chart: {
      path: '/Chart',
      component: Chart,
    },
    ReactComponent: {
      path: '/DashboardPage/ReactComponent',
      component: ReactComponent,
    },
    AngularComponent: {
      path: '/DashboardPage/AngularComponent',
      component: AngularComponent,
    },
    HTMLComponent: {
      path: '/DashboardPage/HTMLComponent',
      component: HTMLComponent,
    },

    // list: {
    //   path: ['/studylist'],
    //   component: StudyListRouting,
    //   condition: appConfig => {
    //     return appConfig.showStudyList;
    //   },
    // },
    list: {
      path: '/Studylist',
      component: StudyListRouting,
      condition: appConfig => {
        return appConfig.showStudyList;
      },
    },

    local: {
      path: '/DashboardPage/local',
      component: ViewerLocalFileData,
    },
    IHEInvokeImageDisplay: {
      path: '/IHEInvokeImageDisplay',
      component: IHEInvokeImageDisplay,
    },
  },
  gcloud: {
    viewer: {
      path:
        '/projects/:project/locations/:location/datasets/:dataset/dicomStores/:dicomStore/study/:studyInstanceUIDs',
      component: ViewerRouting,
      condition: appConfig => {
        return !!appConfig.enableGoogleCloudAdapter;
      },
    },
    list: {
      path:
        '/projects/:project/locations/:location/datasets/:dataset/dicomStores/:dicomStore',
      component: StudyListRouting,
      condition: appConfig => {
        const showList = appConfig.showStudyList;

        return showList && !!appConfig.enableGoogleCloudAdapter;
      },
    },
  },
};

const getRoutes = appConfig => {
  const routes = [];
  for (let keyConfig in ROUTES_DEF) {
    const routesConfig = ROUTES_DEF[keyConfig];

    for (let routeKey in routesConfig) {
      const route = routesConfig[routeKey];
      const validRoute =
        typeof route.condition === 'function'
          ? route.condition(appConfig)
          : true;

      if (validRoute) {
        routes.push({
          path: route.path,
          Component: route.component,
        });
      }
    }
  }

  return routes;
};

const parsePath = async (path, server, params) => {
  let _path = path;
  const _paramsCopy = Object.assign({}, server, params);

  for (let key in _paramsCopy) {
    _path = UrlUtil.paramString.replaceParam(_path, key, _paramsCopy[key]);
  }

  return _path;
};

const parseViewerPath = (appConfig = {}, server = {}, params) => {
  let viewerPath = ROUTES_DEF.default.viewer.path;
  if (window.location.href.includes('/xrad/viewer')) {
    viewerPath = ROUTES_DEF.default.viewerRadx.path;
  } else if (window.location.href.includes('/mrc/viewer')) {
    viewerPath = ROUTES_DEF.default.viewerMrc.path;
  } else if (window.location.href.includes('/radx/viewer')) {
    viewerPath = ROUTES_DEF.default.viewerExe.path;
  }
  else if (appConfig.enableGoogleCloudAdapter) {
    viewerPath = ROUTES_DEF.gcloud.viewer.path;
  }
  else {
    viewerPath = ROUTES_DEF.default.viewer.path;
  }

  return parsePath(viewerPath, server, params);

};

const parseStudyListPath = (appConfig = {}, server = {}, params) => {
  let studyListPath = ROUTES_DEF.default.list.path;
  if (appConfig.enableGoogleCloudAdapter) {
    studyListPath = ROUTES_DEF.gcloud.list.path || studyListPath;
  }

  return parsePath(studyListPath, server, params);
};

export { getRoutes, parseViewerPath, parseStudyListPath, reload };
