import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import { useDrag } from 'react-dnd';
import ImageThumbnail from './ImageThumbnail';
import classNames from 'classnames';
import { Icon } from './../../elements/Icon';

import Tooltip from '@material-ui/core/Tooltip';
import { OverlayTrigger } from './../overlayTrigger';
import { Link } from 'react-router-dom';
import { classes } from '@dcmcloud/core';

const StudyLoadingListener = classes.StudyLoadingListener;
import './Thumbnail.styl';

function ThumbnailFooter({
  SeriesDescription,
  SeriesNumber,
  InstanceNumber,
  numImageFrames,
  hasWarnings,
}) {
  const [inconsistencyWarnings, inconsistencyWarningsSet] = useState([]);

  useEffect(() => {
    let unmounted = false;
    hasWarnings.then(response => {
      if (!unmounted) {
        inconsistencyWarningsSet(response);
      }
    });
    return () => {
      unmounted = true;
    };
  }, []);

  const infoOnly = !SeriesDescription;

  const getInfo = (name, value, icon, className = '') => {
    return (
      <Tooltip title={name} placement="bottom">
        <div className={classNames('item item-series', className)}>
          <div className="icon">{icon}</div>
          <div className="value">{value}</div>
        </div>
      </Tooltip>
    );
  };

  const getWarningContent = inconsistencyWarnings => {
    if (Array.isArray(inconsistencyWarnings)) {
      const listedWarnings = inconsistencyWarnings.map((warn, index) => {
        return <li key={index}>{warn}</li>;
      });

      return <ol>{listedWarnings}</ol>;
    } else {
      return <React.Fragment>{inconsistencyWarnings}</React.Fragment>;
    }
  };

  const getWarningInfo = (SeriesNumber, inconsistencyWarnings) => {
    return (
      <React.Fragment>
        {inconsistencyWarnings && inconsistencyWarnings.length != 0 ? (
          <OverlayTrigger
            key={SeriesNumber}
            placement="left"
            overlay={
              <Tooltip
                placement="left"
                className="in tooltip-warning"
                id="tooltip-left"
              >
                <div className="warningTitle">Series Inconsistencies</div>
                <div className="warningContent">
                  {getWarningContent(inconsistencyWarnings)}
                </div>
              </Tooltip>
            }
          >
            <div className={classNames('warning')}>
              <span className="warning-icon">
                <Icon name="exclamation-triangle" />
              </span>
            </div>
          </OverlayTrigger>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </React.Fragment>
    );
  };
  const getSeriesInformation = (
    SeriesNumber,
    InstanceNumber,
    numImageFrames,
    inconsistencyWarnings
  ) => {
    if (!SeriesNumber && !InstanceNumber && !numImageFrames) {
      return;
    }
    const seriesInformation = (
      <div
        className="series-information"
        style={{ alignItems: 'center', marginRight: ' -1rem' }}
      >
        {getInfo('Series Number', SeriesNumber, <Icon name="series1" />)}
        {getInfo('Instance Number', InstanceNumber, <Icon name="series2" />)}
        {getInfo('Image Frames', numImageFrames, <Icon name="series3" />)}

        <span
          onClick={() => window.open(window.location.href, '_blank')}
          style={{ marginLeft: '-0.5rem' }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.1"
            stroke="black"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
            />
          </svg>
        </span>
      </div>
    );

    return seriesInformation;
  };

  return (
    <div className={classNames('series-details', { 'info-only': infoOnly })}>
      <div className="series-description">[ {SeriesDescription} ]</div>
      {getSeriesInformation(
        SeriesNumber,
        InstanceNumber,
        numImageFrames,
        inconsistencyWarnings
      )}
    </div>
  );
}

function Thumbnail(props) {
  const {
    active,
    altImageText,
    error,
    displaySetInstanceUID,
    imageId,
    imageSrc,
    StudyInstanceUID,
    onClick,
    onDoubleClick,
    onMouseDown,
    supportsDrag,
    showProgressBar,
  } = props;

  const [collectedProps, drag, dragPreview] = useDrag({
    // `droppedItem` in `dropTarget`
    // The only data it will have access to
    item: {
      StudyInstanceUID,
      displaySetInstanceUID,
      type: 'thumbnail', // Has to match `dropTarget`'s type
    },
    canDrag: function (monitor) {
      return supportsDrag;
    },
  });

  const [stackPercentComplete, setStackPercentComplete] = useState(0);
  useEffect(() => {
    const onProgressChange = throttle(({ detail }) => {
      const { progressId, progressData } = detail;
      if (`StackProgress:${displaySetInstanceUID}` === progressId) {
        const percent = progressData ? progressData.percentComplete : 0;

        if (percent > stackPercentComplete) {
          setStackPercentComplete(percent);
        }
      }
    }, 100);

    document.addEventListener(
      StudyLoadingListener.events.OnProgress,
      onProgressChange
    );

    return () => {
      document.removeEventListener(
        StudyLoadingListener.events.OnProgress,
        onProgressChange
      );
    };
  }, [displaySetInstanceUID]);

  const hasImage = imageSrc || imageId;
  const hasAltText = altImageText !== undefined;

  return (
    <div
      ref={drag}
      className={classNames('thumbnail', { active: active })}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onMouseDown={onMouseDown}
    >
      {/* SHOW IMAGE */}
      {hasImage && (
        <ImageThumbnail
          active={active}
          imageSrc={imageSrc}
          imageId={imageId}
          error={error}
          showProgressBar={showProgressBar}
          stackPercentComplete={stackPercentComplete}
        />
      )}
      {/* SHOW TEXT ALTERNATIVE */}
      {!hasImage && hasAltText && (
        <div className={'alt-image-text p-x-1'}>
          <h1>{altImageText}</h1>
        </div>
      )}
      {ThumbnailFooter(props)}
    </div>
  );
}

const noop = () => { };

Thumbnail.propTypes = {
  supportsDrag: PropTypes.bool,
  id: PropTypes.string.isRequired,
  displaySetInstanceUID: PropTypes.string.isRequired,
  StudyInstanceUID: PropTypes.string.isRequired,
  imageSrc: PropTypes.string,
  imageId: PropTypes.string,
  error: PropTypes.bool,
  active: PropTypes.bool,
  stackPercentComplete: PropTypes.number,
  /**
  altImageText will be used when no imageId or imageSrc is provided.
  It will be displayed inside the <div>. This is useful when it is difficult
  to make a preview for a type of DICOM series (e.g. DICOM-SR)
  */
  altImageText: PropTypes.string,
  SeriesDescription: PropTypes.string,
  SeriesNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  InstanceNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasWarnings: PropTypes.instanceOf(Promise),
  numImageFrames: PropTypes.number,
  onDoubleClick: PropTypes.func,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
};

Thumbnail.defaultProps = {
  supportsDrag: false,
  active: false,
  error: false,
  stackPercentComplete: 0,
  onDoubleClick: noop,
  onClick: noop,
  onMouseDown: noop,
};

export { Thumbnail };
